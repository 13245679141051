import Model from './model.js';

class Connector extends Model {
  id = 0;
  name = '';
  isActive = true;
  microservice = null;
  settings = {};
  actions = {};
  mappers = {};

  mapForRequest() {
    return {
      name: this.name,
      microserviceId: this.microservice?.id,
      isActive: this.isActive,
      actions: this.actions,
      settings: this.settings,
      mappers: this.mappers,
    };
  }
}

export default Connector;

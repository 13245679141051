<template>
  <Resource
      :create-request="createHandler"
      :delete-request="deleteHandler"
      :form-component="() => import('@/components/forms/ConnectorForm.vue')"
      :index-request="indexHandler"
      :meta="{name: $tc('connector.title', 1), namePlural: $tc('connector.title', 2)}"
      :model-type="modelType"
      :show-request="showHandler"
      :table-content="tableContent"
      :update-request="updateHandler"
      :can-add="can('client.connector.all')"
      :can-delete="can('client.connector.all')"
      :can-update="can('client.connector.all')"
  />
</template>

<script lang="js">
import { create, index, remove, show, update } from '@/api/endpoints/connector.js';
import Connector from '@/application/models/connector.js';
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { mapGetters } from "vuex";

export default {
  name: 'ConnectorResource',
  components: {
    Resource,
  },
  props: {
    client: {
      required: true,
      type: Object,
    }
  },
  computed: {
    ...mapGetters("profile", ["can"]),
    showHandler: () => show,
    updateHandler: () => update,
    deleteHandler: () => remove,
    modelType: () => Connector,
    tableContent() {
      return [
        {
          text: this.$t('connector.fields.name'),
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('connector.fields.isActive'),
          sortable: true,
          value: 'isActive',
          columnType: 'checkbox',
        },
      ];
    },
  },
  created() {
    eventBus.$emit('setBreadcrumbs', [
      {
        exact: true,
        to: { name: 'home' },
        text: this.$t('global.dashboard'),
      },
      {
        exact: true,
        to: { name: 'client.index' },
        text: this.$tc('client.title', 2),
      },
      {
        exact: true,
        to: { name: 'client.connectors' },
        text: this.client.name,
      },
    ]);
  },
  methods: {
    indexHandler() {
      return index(this.$route.params.clientId, ...arguments);
    },
    createHandler() {
      return create(this.$route.params.clientId, ...arguments);
    },
  },
};
</script>
